const state = {
  //分页加载是否到了最后一页
  isReachLastPage: false,
  //分页加载post是否忙碌
  isPageLoadBusy: false,
  //分页加载HTML是否展示
  isPageLoadShow: false,

  //默认初始总页数99页
  totalPageCount: 99,
  //默认初始页数 第1页
  curPageNumber: 1,
}

// getters
const getters = {
  projectsQTY: state => {
    return state.projects.length;
  }
}
export default {
  namespaced: true,
  state,// 处理数据的唯一途径，state的改变或赋值只能在这里
}
