import { createRouter, createWebHistory } from 'vue-router'
import { store } from './store/index'
//异步组件加载方式
const Home = () =>
    import ('../src/pages/home');
const Audio = () =>
    import ('../src/pages/audio');
const Chat = () =>
    import ('../src/pages/chat');
const ArticleDetail = () =>
    import ('../src/pages/articledetail');
const Personal = () =>                                                      
    import ('../src/pages/personal');
const Login = () =>
    import ('../src/pages/login');
const NotFound = () =>
    import ('../src/pages/404.vue');
const Setting = () =>
    import ('../src/pages/setting.vue');
const About = () =>
    import ('../src/pages/about.vue');
const FeedBack = () =>
    import ('../src/pages/feedback.vue');
const Agreement = () =>
    import ('../src/pages/agreement.vue');
const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'default',
        component: Chat,
        meta: {
            keepAlive: true, // 需要缓存
            title: 'GPT4',
            tabIdx: 1
        }
    },
    // 如果你省略了最后的 `*`，在解析或跳转时，参数中的 `/` 字符将被编码
    {
        path: '/index',
        name: 'index',
        component: Home,
        meta: {
            keepAlive: true, // 需要缓存
            title: 'ChatGPT',
            tabIdx: 1
        }
    },
    {
        path: '/audio',
        name: 'audio',
        component: Audio,
        meta: {
            keepAlive: true, // 需要缓存
            title: '录音',
            tabIdx: 1
        }
    },
    {
        path: '/chat',
        name: 'chat',
        component: Chat,
        meta: {
            keepAlive: true, // 需要缓存
            title: 'Chat-GPT',
            tabIdx: 1
        }
    },
    {
        path: '/articledetail',
        name: 'articledetail',
        component: ArticleDetail,
        meta: {
            keepAlive: false, // 需要缓存
            title: '文章详情',
            tabIdx: 1
        }
    },
    {
        path: '/personal',
        name: 'personal',
        component: Personal,
        meta: {
            keepAlive: false, // 需要缓存
            title: '我的',
            tabIdx: 1
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            keepAlive: true, // 需要缓存
            title: '关于我们',
            tabIdx: 4
        }
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: FeedBack,
        meta: {
            keepAlive: false, // 需要缓存
            title: '意见反馈',
            tabIdx: 4
        }
    },
    {
        path: '/agreement',
        name: 'agreement',
        component: Agreement,
        meta: {
            keepAlive: true, // 需要缓存
            title: '用户协议',
            tabIdx: 4
        }
    },
    {
        path: '/setting',
        name: 'setting',
        component: Setting,
        meta: {
            keepAlive: true, // 需要缓存
            title: '设置',
            tabIdx: 4
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            keepAlive: false,
            tabIdx: 1
        }
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes, // `routes: routes` 的缩写
})
router.beforeEach((to, from, next) => {
    // console.log(from);
    next()
})
router.afterEach(route => {
    // 从路由的元信息中获取 title 属性
    if (route.meta.title) {
        document.title = route.meta.title;
        var channel = route.query.c;
        var share = route.query.s;
        store.dispatch('user/trace', { channel: channel, share: share });
        // if (route.name == 'feiyan') {
        //     // 插入 meta 标签
        //     var oMeta = document.createElement('meta');
        //     oMeta.content = 'width=750,user-scalable=no, viewport-fit=cover';
        //     oMeta.name = 'viewport';
        //     document.getElementsByTagName('head')[0].appendChild(oMeta);
        // }
        // // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
        // if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        //     const hackIframe = document.createElement('iframe');
        //     hackIframe.style.display = 'none';
        //     hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
        //     document.body.appendChild(hackIframe);
        //     setTimeout(_ => {
        //         document.body.removeChild(hackIframe)
        //     }, 300)
        // }
    }
});

export default router