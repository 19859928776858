// vue3 导入方式
import { createVNode, render, getCurrentInstance } from 'vue'
// Message 组件导入
import Message from './message.vue'
let $data;
export default {
    install(app) {
        if (!$data) {
            // Loading 为自己写的组件 
            $data = createVNode(Message, {}, {
                default: () => createVNode()
            });
            $data.appContext = app._context; // 这句很关键，关联起了数据
            render($data, document.body);
        }
        $data.component.proxy.setShow = false;
        let message = {
            loading(msg) {
                $data.component.proxy.loading(msg);
            },
            confirm(msg, yes, no) {
                $data.component.proxy.confirm(msg, yes, no);
            },
            info(msg, timer) {
                $data.component.proxy.info(msg, timer);
            },
            hide() {
                $data.component.proxy.hide();
            }
        };
        if (!app.message) {
            app.message = message;
        }
        app.config.globalProperties.message = app.message;
    }
}