/*
 * @Date: 2021-12-22 10:45:01
 * @LastEditors: WangZhengJie wangzj@geovis.com.cn
 * @LastEditTime: 2022-11-13 21:57:24
 * @FilePath: \chatgpt-web-client\src\main.js
 */
// import Vue from 'vue'
import { createApp } from 'vue'
import App from './App'
import router from './routes'
import { store } from './store/index'
import config from './config'
import $ from "jquery";
//import VueJsonp from 'vue-jsonp'
//import VueWechatTitle from 'vue-wechat-title'
//v-lazy
// import VueLazyload from 'vue-lazyload'
//无限滚动
import vueiInfinite from 'vue-infinite-scroll'
//引入自定义全局组件
import HeaderBar from '@/components/headerbar'
import FooterBar from '@/components/footerbar'
import ADialog from '@/components/adialog'
import Tabbar from '@/components/tabbar'
import Message from '@/components/message/message.js'
import i18n from '@/globalization/index'
import { Toast } from 'vant';

// 预览组件以及样式
// import VMdPreview from '@kangc/v-md-editor/lib/preview';
// import '@kangc/v-md-editor/lib/style/preview.css';
// // VuePress主题以及样式（这里也可以选择github主题）
// import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
// import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
// // Prism
// import Prism from 'prismjs';
// // 代码高亮
// import 'prismjs/components/prism-json';
// // 选择使用主题
// VMdPreview.use(vuepressTheme, {
//     Prism,
// });
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
const app = createApp(App)
app.use(router)
app.use(store)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(Message)
app.use(i18n)
app.use(Toast);
// 引入v-md-editor预览组件
// app.use(VMdPreview);
    //app.use(VueJsonp)
    //app.use(VueWechatTitle)
    // app.use(VueLazyload, {
    //     loading: require('./../static/images/img-transparent.png'), //加载中图片，一定要有，不然会一直重复加载占位图
    //     error: require('./../static/images/img-transparent.png') //加载失败图片
    // });

app.use(vueiInfinite)
app.component('headerbar', HeaderBar)
app.component('footerbar', FooterBar)
app.component('adialog', ADialog)
app.component('tabbar', Tabbar)
    // app.component('loading', Loading)

app.config.globalProperties.$jquery = $ // 自定义添加
    //app.config.globalProperties.$jquery = $ // 自定义添加
app.config.globalProperties.global = config
app.mount('#app')