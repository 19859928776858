<template>
  <div class="message" v-show="setShow">
      <div class="icon">
        
      </div>
       <div class="alert" v-if="msgShow">
          <div  class="msg info">
            <p>{{msg}}</p>
          </div>
        </div>
      <div v-if="loadingShow" class="msg">
        <p>{{msg}}</p>
      </div>
      <div class="content" v-show="optShow" >
        <div class="msg">
          <p>{{msg}}</p>
        </div>
         <div class="opt" v-show="optShow" >
        <button class="btn btn-mini btn-primary" @click="yes">确定</button>
        <button  class="btn btn-mini" @click="no">关闭</button>
      </div>
      </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  // install(app) {
  // //   debugger
  //  app.config.globalProperties.$loading = this;
  // //    alert(111);
  // },
  setup() {
    const setShow = ref(false);
    const optShow = ref(false);
    const msgShow = ref(false);
    const loadingShow = ref(false);
    const timer = ref(1000);
    const msg = ref('message');
    const callback=ref({});
    const loading = (_msg) => {
      setShow.value = true;
      optShow.value=false;
      loadingShow.value=true;
      msg.value=_msg;
    };
     const info = (_msg,_timer) => {
      setShow.value = true;
      msg.value=_msg;
      optShow.value=false;
      loadingShow.value=false;
      msgShow.value=true;
      timer.value=_timer|2000;
      setTimeout(() => {
        setShow.value = false;
        msgShow.value=false;
        msg.value="";
      }, timer.value);
    };
    const confirm = (_msg,_yes,_no) => {
      setShow.value = true;
      msg.value=_msg;
      optShow.value=true;
      loadingShow.value=false;
      callback.value.yes=_yes;
      callback.value.no=_no;
    };
    const hide = () => {
      setTimeout(() => {
        setShow.value = false;
        msg.value="";
      }, 300);
    };
    return {
      setShow,
      optShow,
      msgShow,
      loadingShow,
      loading,
      confirm,
      info,
      hide,
      msg,
      callback
    };
  },
  created: function() {

  },
  data() {
    return {
      // visible:false
    };
  },
  methods: {
    yes:function(){
      this.callback.yes&&this.callback.yes();
    },
    no:function(){
      this.callback.no&&this.callback.no();
    }
    // hide:function(){
    //     this.visible=false
    // },
    // show:function(){
    //     this.visible=true
    // }
  },
  beforeDestroy: function () {
                //卸载组件
  },
  mounted: function () {
    //this.show();
  },
};
</script>
<style lang="css" scoped>

.message{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top:0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.message .content{
  padding:15px;
  width: 80%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius:5px;
}
.message .content .msg{
  padding: 10px 0;
  color: #333;
}
.message .alert{
  padding:15px;
  width: 80%;
  background: rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius:5px;
}
.message .alert .info{
  color:#fff !important;
}
.message .content .opt{
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message .content .opt button{
  margin-right: 10px;
}

.message .content .opt button:lase-of-type{
  margin-right: 0px;
}
.message .icon{
  
}
.message .msg{
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
</style>
