//import state from './commonState';
//import mutations from './mutations';


const actions = {
  commonActions1 ({ commit},{val,pageNumber}) {
        console.log('11225466');

      },
  }


export default {
  namespaced: true,
  actions// 处理数据的唯一途径，state的改变或赋值只能在这里
}
