<!-- App.vue -->
<template>
  <div v-cloak>
    <!-- <loading ref="loading"/> -->
        <router-view v-slot="{ Component }">
          <transition :name="transitionName" mode="out-in">
                <keep-alive v-if="$route.meta.keepAlive">
                  <component :is="Component" />
                </keep-alive>
                <component v-else :is="Component" />
          </transition>
        </router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {
  },
  data() {
    return {
      routerTime: 0,
      transitionName: "slide-left",
      loading:null,
    };
  },
  methods: {
  },
  created:function () {
      
  },
  mounted() {
  
  }
};
</script>
<style scoped>
body{
    padding: 0;
    margin: 0;
    font-family: PingFangSC-Semibold;
}
.slide-left-enter-from {
	opacity: 0.2;
}
.slide-left-enter-to {
	opacity: 1;
}
 
.slide-left-leave-from {
		opacity: 1;
}
 
.slide-left-leave-to {
	opacity: 0.8;
}
 
.slide-left-enter-active,
.slide-left-leave-active {
	transition: all 0.2s;
}

</style>
