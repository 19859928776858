<template>
<div class="kyx-diaolog-mask" v-on:click="hide" v-if="visible">
  <div class="kyx-diaolog" :class="posClass">
    <div class="header">
      <div class="close" v-on:click="hide">
          <img src="@/assets/icon/close.png"/>
      </div>
    </div>
     <div class="body">
        <slot></slot>
      </div>
  </div>
  </div>
</template>
<script>
export default {
  created: function() {},
  data() {
    return {
      visible: false,
      posClass:{'kyx-diaolog-bottom':false,'kyx-diaolog-center':false}
    };
  },
  methods: {
    show: function() {
      this.visible=true;
    },
    hide:function(){
      this.visible=false;
    }
  },
  beforeDestroy: function () {
                //卸载组件
  },
  mounted: function () {
     if(this.position=='center'){
       this.posClass['kyx-diaolog-center']=true;
     }
     else{
       this.posClass['kyx-diaolog-bottom']=true;
     }
  },
  props: {
      position: {
        type: String,
        required: false,
        default:'bottom'
      },
  },
};
</script>
<style lang="css" scoped>
    .kyx-diaolog-mask{
       height: 100vh;
       width: 100vw;
       background: rgba(0,0,0,0.3);
       position: fixed;
       top:0;
       z-index: 888;
    }
    .kyx-diaolog-bottom{
      bottom: 0px;
    }
     .kyx-diaolog-center{
      top: 50%;
      bottom: auto !important;
      transform: translateY(-50%);
      left:10%;
      width:80% !important;
    }
    .kyx-diaolog{
          position: fixed;
          bottom: 0px;
          /* left: 10%; */
          /* height: 100px; */
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width:100%;
          z-index: 999;
          border-radius: 5px;
          background: #fff;
          /* box-shadow: #eee 0px 0px 2px 2px; */
    }
    .kyx-diaolog .header{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 0;
        width: 100%;
    }
    .kyx-diaolog .body{
          /* height: 100px; */
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 40px 0;
          padding-top: 0px;
          width: 100%;
          z-index: 999;
          background: #fff;
    }
    .kyx-diaolog .header .close{
       height: 30px;
    padding: 0 10px;
    }
    .kyx-diaolog .header img{
       height: 100%;       
    }
</style>
