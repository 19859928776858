<template>
    <div class="header">
      <div class="left" v-on:click="leftClick"><img v-show="leftvisible" class="icon" src="../assets/icon/back.png"/></div>
      <div class="center">
        <img v-show="logovisible" class="logo" src="@/assets/logo_beltoo.jpg"/>
        <h2 v-if="title" class="title">{{title}}</h2>
      </div>
      <div class="right"></div>
    </div>
</template>
<script>
export default {
  created: function() {

  },
  data() {
    return {
     
    };
  },
  methods: {
    leftClick:function(){
      if (history.length >2) {
        history.back();
      }
      else{
        this.$router.push({name:'index'});
      }
      this.$emit('back');
    },
    tabClick:function(tab){
      for(var i=0;i<this.tabs.length;i++){
        if(tab.idx==this.tabs[i].idx){
          this.tabs[i].active=true;
        }
        else{
          this.tabs[i].active=false;
        }
      }
      this.$emit('tabChanged',tab);
    }
  },
  beforeDestroy: function () {
                //卸载组件
  },
  props: {
      title: {
        type: String,
        required: false,
        default:''
      },
      leftvisible:{
         type: Boolean,
        required: false,
        default:false
      },
      logovisible:{
        type: Boolean,
        required: false,
        default:false
      }
  },
};
</script>
<style lang="css" scoped>
.header{
    /* position: fixed;
    top: 0; */
    height: 13.3vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
     -webkit-box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
    box-shadow: 0 1.067vw 2.667vw 0 rgb(0 0 0 / 6%);
}
.header .title{
    font-size: 5.333vw;
    font-family: qsdm,PingFangSC-Semibold;
    font-weight: 700;
    color: #212831;
    text-align: center;
}
.header .left,.header .right{
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .left .icon{
    height: 2.3vh;
}
.header .center{
  flex:1;
  display: flex;
    justify-content: center;
    align-items: center;
}
.header .center .logo{
  height: 4.3vh;
  margin-right: 2vw;
}
</style>
