/*
 * @Author: your name
 * @Date: 2021-12-22 10:45:01
 * @LastEditTime: 2023-04-24 15:54:03
 * @LastEditors: OBKoro1
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEd
 * @FilePath: \chatgpt-web-client\src\services\GPTService.js
 */
import ApiService from './ApiService'
class GPTService extends ApiService {
    ExpressLogin(data) {
        return super.Post("/api/gpt/expresslogin", data);
    }
    Chat(data) {
        return super.Post('/api/gpt/generate1/', data);
    }
    StopChat(sessionID) {
        return super.Get('/api/gpt/stopGenerate1?sessionID='+sessionID);
    }
    CompanyPageList(filter) {
        return super.Post('/api/gpt/companys/', filter);
    }
    ApplyCompany(data) {
        return super.Post('/api/gpt/applyCompany/', data);
    }
    ApproveCompany(data) {
        return super.Post('/api/gpt/approveCompany/', data);
    }
    GetCompanyUsers(companyID) {
        return super.Get('/api/gpt/companyusers?companyID='+companyID);
    }
    GetCompanyUserPageList(filter) {
        return super.Post('/api/gpt/companyuserlist',filter);
    }
    SetUserCompany(phone,companyID) {
        var _data={phone,companyID};
        return super.Post('/api/gpt/setUserCompany',_data);
    }
    RemoveUserCompany(userID) {
        var _data={userID};
        return super.Post('/api/gpt/removeUserCompany',_data);
    }
    RemoveSession(sessionID){
        return super.Get('/api/gpt/removeSession?id='+sessionID);
    }
    SaveSession(session){
        return super.Post('/api/gpt/saveSession/',session);
    }
    SessionList(){
        return super.Get('/api/gpt/sessions/');
    }
    ChatList(sessionid){
        return super.Get('/api/gpt/chatlist?sessionid='+sessionid);
    }
    ChatPageList(filter){
        return super.Post('/api/gpt/ChatPageList',filter);
    }
    UserInfo() {
        return super.Get("/api/gpt/userinfo", (data) => {
            if (data.Success) {
                // commit('userInfo', data.Data);
            }
        }, (e) => {
            console.log(e);
        });
    }
    SaveUserInfo(data){
        return super.Post('/api/gpt/saveUserInfo',data);
    }
    RechargeCompanyWordCount(data){
        return super.Post('/api/gpt/rechargeCompanyWordCount',data);
    }
    RechargeUserWordCount(data){
        return super.Post('/api/gpt/rechargeUserWordCount',data);
    }
    SaveGPTSetting(data){
        return super.Post('/api/gpt/saveGPTSetting',data);
    }
    GetGPTSetting(){
        return super.Get('/api/gpt/getGPTSetting');
    }
    DeleteGPTSetting(keyID){
        return super.Delete('/api/gpt/deleteGPTSetting?id='+keyID);
    }
    // ChatStream(data) {
       
    //     return super.Post('/api/gpt/chatStream/', data);
    // }
}
export default new GPTService()